import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import router from 'next/router'
import { TopGridProps } from '@/props/Home';
import styles from '@/styles/top-grid.module.scss';
import { sortItems } from '../../../../utils';
import Section from "../../../../components/common/Section";
import { routerPush } from '../../../../utils';
import { cityLinkGenerator, getCookieCitySlug, getSelectedCityFromCookie } from '../../../../utils/cities';
import { topGridClicked } from '../../../../tracking/segment/homePage';
import parse from 'html-react-parser'
import { isMobile } from 'react-device-detect';

const TopGrid = (props: TopGridProps) => {
    const [items, setItems] = useState<any>([...Array(9).keys()])
    useEffect(() =>{
        let sorted_items = props.items?.length ? sortItems(props) : []
        sorted_items.length && setItems(sorted_items.slice(0, isMobile ? 9 : 10))
    },[isMobile, getSelectedCityFromCookie(), props.items])
    return (
        <Section className={`${styles.top_grid_parent} section_custompad`} title={props.title || "Explore Our Services"} hasPadding={true} noTitleEL={!props.has_cap}>
            <div className={`container ${styles.grid}`}>
                    {
                        items.map((item, index) => {
                            return <div className={styles.card_parent} key={index}><Card {...props} item={item} router={router} index={index+1}/></div>
                        })
                    }
            </div>
        </Section>
    )
}


const Card = (props: any) => {
    const citySlug = getCookieCitySlug(props.generic.userCity)
    const [isMobile, setMobile] = useState(false)
    useEffect(() => setMobile(window.innerWidth < 992));

    const handleClick = (e) => {
        e.preventDefault();
        routerPush(props.router, link);
        let location = getSelectedCityFromCookie()
        topGridClicked({'item_position':props.index,'target_link':link, 'item_label':props.item.title === 'iPhone' ? 'iPhone iProtect Gold' :props.item.title, 'location': location?location:'' }) // Segment  Event
    }
    let link = cityLinkGenerator(props.item, citySlug)
    const image_path = props.item.image_path
    return (
        <a className={styles.card} href={link} onClick={handleClick}>
            <div className={`${styles.image} ${props.item.is_new && styles.is_new}`}>
                <div className={`${styles.bg_circle}`} style={{width : isMobile ? "54px" : "80px", height : isMobile ? "54px" : "80px"}}></div>
                <div className={`${image_path && (isMobile ? styles.mobile_sprite : styles.sprite)} ${ image_path && styles[image_path]}`} style={{width : isMobile ? "54px" : "80px", height : isMobile ? "54px" : "80px"}}></div>
            </div>
            {
                props.item.offer &&
                <div className={styles.dicount_container}>
                    <p className={styles.discount_text}>{props.item.offer}</p>
                </div>
            }
            <p className={styles.lable}>{props.item.title || 'item'}</p>
           {isMobile && props.item.gold_title_mobile && parse(props.item.gold_title_mobile)}
           {!isMobile && props.item.gold_title_web && parse(props.item.gold_title_web)}
        </a>
    )
}
const mapStateToProps = (state) => {
    return {
        generic: state.genericReducer
    }
}

export default connect(mapStateToProps, null)(TopGrid);

